import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Modal,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import useHelps from "../../hooks/useHelps";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainPaperContainer: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 200px)",
  },
  mainPaper: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  helpPaper: {
    position: "relative",
    width: "100%",
    minHeight: "340px",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "340px",
  },
  paperHover: {
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.03)",
      boxShadow: `0 0 8px`,
      color: theme.palette.primary.main,
    },
  },
  videoThumbnail: {
    width: "100%",
    height: "calc(100% - 56px)",
    objectFit: "cover",
    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
  },
  videoTitle: {
    marginTop: theme.spacing(1),
    flex: 1,
  },
  videoDescription: {
    maxHeight: "100px",
    overflow: "hidden",
  },
  videoModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoModalContent: {
    outline: "none",
    width: "90%",
    maxWidth: 1024,
    aspectRatio: "16/9",
    position: "relative",
    backgroundColor: "white",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },
}));

const Helps = () => {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const { list } = useHelps();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showAnnouncementDialog, setShowAnnouncementDialog] = useState(true); // Estado para controlar o modal de anúncio

  useEffect(() => {
    async function fetchData() {
      const helps = await list();
      setRecords(helps);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const showDueDateModal = localStorage.getItem("showDueDateModal");

    if (showDueDateModal === null || showDueDateModal === "true") {
      setShowAnnouncementDialog(true);
    } else {
      setShowAnnouncementDialog(false);
    }
  }, []);
  const openVideoModal = (video) => {
    setSelectedVideo(video);
  };

  const closeVideoModal = () => {
    setSelectedVideo(null);
  };

  const handleModalClose = useCallback((event) => {
    if (event.key === "Escape") {
      closeVideoModal();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleModalClose);
    return () => {
      document.removeEventListener("keydown", handleModalClose);
    };
  }, [handleModalClose]);

  const handleCloseAnnouncementDialog = () => {
    setShowAnnouncementDialog(false);
    localStorage.setItem("showDueDateModal", "false");
  };

  const renderAnnouncementDialog = () => {
    if (!showAnnouncementDialog) return null;

    const localStorageCreatedAt = localStorage.getItem("createdAt");
    const localStorageDueDate = localStorage.getItem("dueDate");

    const createdAt = moment(localStorageCreatedAt);
    const dueDateMoment = moment(localStorageDueDate);

    const diff = dueDateMoment.diff(createdAt, "days");

    if (diff > 3) return null;

    return (
      <Dialog
        open={showAnnouncementDialog}
        onClose={handleCloseAnnouncementDialog}
        aria-labelledby="announcement-dialog-title"
        aria-describedby="announcement-dialog-description"
      >
        <DialogTitle id="announcement-dialog-title">Teste grátis</DialogTitle>
        <div
          style={{
            border: "1px solid #f1f1f1",
            margin: "0 auto 20px",
            textAlign: "center",
            width: "400px",
            height: 300,
            backgroundImage: `url(https://i.ibb.co/MRk5X5j/aviso.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        ></div>
        <DialogContent>
          <DialogContentText id="announcement-dialog-description">
            🚀 <strong>Teste TexIA por 3 Dias GRÁTIS!</strong> 🚀
            <br />
            <br />
            🎉 Aproveite o teste gratuito de 3 dias da TexIA!
            <br />
            <br />
            ⚠️ <strong>Atenção:</strong> para continuar com acesso completo,
            faça o pagamento do plano antes do prazo acabar! 💳⏳
            <br />
            <br />
            Após 3 dias, sem o pagamento:
            <br />
            🔒 Sua conta será bloqueada
            <br />
            🗑️ Seus dados serão deletados
            <br />
            <br />
            👉 Para reativar, fale com o suporte! 💬
            <br />
            <br />
            Para efetuar o pagamento, é só clicar em <strong>
              Financeiro
            </strong>{" "}
            no menu lateral 💸.
            <br />
            <br />
            📲 <strong>Suporte:</strong> +55 11 96618-8776
            <br />
            📧 <strong>E-mail:</strong> contato@texia.com.br
            <br />
            <br />
            👋 Aproveite e seja bem-vindo(a) à TexIA! ✨ bem-vindo(a) à TexIA!
            ✨
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseAnnouncementDialog}
            color="primary"
            autoFocus
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderVideoModal = () => (
    <Modal
      open={Boolean(selectedVideo)}
      onClose={closeVideoModal}
      className={classes.videoModal}
    >
      <div className={classes.videoModalContent}>
        {selectedVideo && (
          <iframe
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            src={`https://www.youtube.com/embed/${selectedVideo}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>
    </Modal>
  );

  const renderHelps = () => (
    <div className={`${classes.mainPaper} ${classes.mainPaperContainer}`}>
      {records.length
        ? records.map((record, key) => (
            <Paper
              key={key}
              className={`${classes.helpPaper} ${classes.paperHover}`}
              onClick={() => openVideoModal(record.video)}
            >
              <img
                src={`https://img.youtube.com/vi/${record.video}/mqdefault.jpg`}
                alt="Thumbnail"
                className={classes.videoThumbnail}
              />
              <Typography variant="button" className={classes.videoTitle}>
                {record.title}
              </Typography>
              <Typography
                variant="caption"
                className={classes.videoDescription}
              >
                {record.description}
              </Typography>
            </Paper>
          ))
        : null}
    </div>
  );

  return (
    <MainContainer>
      <MainHeader>
        <Title>
          {i18n.t("helps.title")} ({records.length})
        </Title>
        <MainHeaderButtonsWrapper></MainHeaderButtonsWrapper>
      </MainHeader>
      <div className={classes.mainPaper}>{renderHelps()}</div>
      {renderAnnouncementDialog()}
      {renderVideoModal()}
    </MainContainer>
  );
};

export default Helps;
